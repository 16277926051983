export default function providerRoutes () {
	return [{
		path: 'suppliers',
		title: 'Nhà cung cấp',
		element: <>Suppliers</>,
		children: [{
			path: ':supplierId',
			element: <>Form</>
		}]
	}]
}
