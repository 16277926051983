import PageHeader from "@/layouts/components/PageHeader";
import React, {Suspense, useState} from "react";
import {Card, CircularProgress} from "@mui/material";
import {Await, defer, Outlet, useLoaderData} from "react-router-dom";
import UserTable from "@/modules/User/components/UserTable";
import {GridSelectionModel} from "@mui/x-data-grid";
import {ResponseObject} from "@/@core/entities/Http";
import User from "@/modules/User/entities/User";
import {client, clientRole} from "@/modules/User/services/UserServices";
import Role from "@/modules/Role/entities/Role";

export type UserLoaderResult = {
	users: Promise<ResponseObject<User[]>>
	roles: Promise<ResponseObject<Role[]>>
}

export const loader = ({request}) => {
	const url = new URL(request.url);
	const urlRoles = new URL(`${process.env.REACT_APP_API_ENDPOINT}roles`);
	return defer({
		users: client.fetchItems(Object.fromEntries(url.searchParams)),
		roles: clientRole.fetchItems(Object.fromEntries(urlRoles.searchParams)),
	});
}

export default function Users() {
	const data = useLoaderData() as UserLoaderResult;
	const [selected, setSelected] = useState<GridSelectionModel>([]);
	// const actions: ReactElement[] = []

	return (
		<>
			<PageHeader actions={[]}/>
			<Card>
				<Suspense fallback={<CircularProgress/>}>
					<Await
						resolve={data.users}
						children={(response) => (
							<UserTable
								dataSource={response}
								selectedEntries={selected}
								onSelectionChanged={setSelected}
							/>
						)}
					/>
				</Suspense>
			</Card>
			<Outlet/>
		</>
	)
}
