import {
	FormContainer,
	RadioButtonGroup,
	TextFieldElement,
	useForm
} from 'react-hook-form-mui';
import {
	Button,
	Stack
} from '@mui/material';
import {
	LoadingButton,
	TabContext,
	TabPanel
} from '@mui/lab';
import {
	Language,
	languages
} from '@/config/languages';
import { Icon } from '@iconify/react';
import React, {
	useEffect,
	useState
} from 'react';
import {
	defer,
	useNavigate,
	useRevalidator
} from 'react-router-dom';

import LanguageTabBar from '@/@core/components/LanguageTabBar';
import icons from '@/config/icons';
import { useQuery } from '@/@core/services/HttpService';
import { ResponseStatus } from '@/@core/entities/Http';
import toast from 'react-hot-toast';
import { client } from '@/modules/Category/services/CategoryService';
import { prepareLocalizationFormData } from '@/@core/services/ApiService';

export async function loader ({ params }) {
	if (params.categoryId === 'add') {
		return defer({
			formData: new Promise((resolve) => resolve({
				status: ResponseStatus.SUCCESS,
				data: {
					contents: []
				}
			}))
		});
	}

	return defer({
		formData: client.fetchItem(params.categoryId)
	});
}

function ContentForm ({ language }: { language: Language }) {
	return (
		<Stack spacing={4}>
			<TextFieldElement
				autoFocus
				required
				name={`contents.${language.localeCode}.name`}
				label="Tên danh mục"
				variant="outlined"
				size="small"
				sx={{ width: 480 }}
			/>
			<TextFieldElement
				name={`contents.${language.localeCode}.alias`}
				label="Đường dẫn URL"
				variant="outlined"
				size="small"
				sx={{ width: 480 }}
			/>
			<TextFieldElement
				name={`contents.${language.localeCode}.description`}
				label="Mô tả"
				variant="outlined"
				size="small"
				multiline
				rows={4}
				sx={{ width: 480 }}
			/>
		</Stack>
	)
}

export default function CategoryForm ({ formData }) {
	const [activeLanguage, setActiveLanguage] = useState('vi_VN');
	const { response, busy, callback: submitData } = useQuery(client.saveItem, undefined);
	const { revalidate } = useRevalidator();

	const navigate = useNavigate();
	const formContext = useForm({
		defaultValues: prepareLocalizationFormData(formData)
	});

	/**
	 * Helper function to close dialog and reload data
	 */
	const closeDialog = () => {
		navigate('..');
		revalidate();
	}

	useEffect(() => {
		/**
		 * Show notification after submitted data
		 */
		if (response.status === ResponseStatus.SUCCESS && busy === false) {
			toast.success('Đã lưu thông tin danh mục thành công!');
			closeDialog();
		}
	}, [response, busy]);

	return (
		<FormContainer formContext={formContext} onSuccess={(formData) => submitData(formData)}>
			<TabContext value={activeLanguage}>
				<LanguageTabBar
					onChange={(event, value) => setActiveLanguage(value)}
					variant='pilled'
				/>

				{languages.map(language => (
					<TabPanel
						key={language.localeCode}
						value={language.localeCode}
						sx={{ px: 0, pb: 6 }}>
						<ContentForm language={language} />
					</TabPanel>
				))}
			</TabContext>

			<RadioButtonGroup
				options={[{
					id: 'ACTIVE',
					label: 'Active'
				}, {
					id: 'DEACTIVE',
					label: 'Inactive'
				}]}
				name="status"
				label="Trạng thái"
				required
				row
			/>

			<Stack direction="row" spacing={2} justifyContent="space-between" pt={6}>
				<Button disabled={busy} onClick={closeDialog} variant="outlined" color="secondary">{ 'Hủy' }</Button>
				<LoadingButton loading={busy} type="submit" variant="contained" startIcon={<Icon icon={icons.CHECK} />}>{ 'Lưu' }</LoadingButton>
			</Stack>
		</FormContainer>
	)
}
