import CustomerList, {
	loader as customersLoader,
} from "@/modules/Customers/pages";

export default function customersRoutes() {
	return [
		{
			path: "customers",
			title: "Danh sách khách hàng",
			loader: customersLoader,
			element: <CustomerList />,
			children: [
				{
					path: ":customersId",
				},
			],
		},
	];
}
