import { Await, defer, useLoaderData, Outlet } from "react-router-dom";
import { Suspense, useState } from "react";
import { Card, CircularProgress } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import { ResponseObject } from "@/@core/entities/Http";
import CustomersTableHeader from "../components/CustomersTableHeader";
import CustomersTable from "../components/CustomersTable";
import { Customers } from "../entities/Customers";
import { client } from "../services/CustomersService";

export type CustomersLoaderResult = {
	customers: Promise<ResponseObject<Customers[]>>;
};

export const loader = ({ request }) => {
	const url = new URL(request.url);
	return defer({
		customers: client.fetchItems(Object.fromEntries(url.searchParams)),
	});
};

export default function CustomersList() {
	const data = useLoaderData() as CustomersLoaderResult;
	const [selectedCustomers, setSelectedCustomers] =
		useState<GridSelectionModel>([]);
	return (
		<>
			<Card>
				<CustomersTableHeader selectedEntries={selectedCustomers} />
				<Suspense fallback={<CircularProgress />}>
					<Await
						resolve={data.customers}
						children={(response) => (
							<CustomersTable
								dataSource={response}
								selectedEntries={selectedCustomers}
								onSelectionChanged={setSelectedCustomers}
							/>
						)}
					/>
				</Suspense>
			</Card>
			<Outlet />
		</>
	);
}
