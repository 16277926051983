import {
	ResponseObject,
	ResponseStatus
} from '../entities/Http';
import { defaultsDeep } from 'lodash/fp'
import {
	useMemo,
	useState
} from 'react';

export type QueryParams = {
	[key: string]: any
}

export type SearchParamEntry = {
	name: string,
	value: string
}

export function api(requestPath: string): URL {
	return new URL(`${process.env.REACT_APP_API_ENDPOINT}${requestPath}`);
}

export function withParams(
	url: string | URL,
	params: SearchParamEntry[]
) {
	const urlWithParams = new URL(url);
	params.forEach(param => urlWithParams.searchParams.append(param.name, param.value));

	return urlWithParams;
}

const defaultHeaders = defaultsDeep({
	'Content-Type': 'application/json'
});

const withHeaders = (_headers: { [key: string]: string }) => {
	const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
	const headers = defaultHeaders(_headers);

	if (token) {
		headers['Authorization'] = `Bearer ${token}`;
	}

	return headers;
}

/**
 * @param url
 * @param headers
 */
export async function get<T>(
	url: string | URL,
	headers: { [key: string]: any } = {}
): Promise<ResponseObject<T>> {
	const options = {
		headers: withHeaders(headers),
		method: 'GET'
	}

	return await fetch(url, options).then(response => {
		return response.json() as Promise<ResponseObject<T>>
	})
}

export async function post<T> (
	url: string | URL,
	data: T,
	headers: { [key: string]: any } = {}
) {
	const options = {
		headers: withHeaders(headers),
		method: 'POST',
		body: JSON.stringify(data)
	};

	return await fetch(url, options).then(response => {
		return response.json() as Promise<ResponseObject<T>>
	})
}

export async function put<T> (
	url: string | URL,
	data: T,
	headers: { [key: string]: any } = {}
) {
	const options = {
		headers: withHeaders(headers),
		method: 'PUT',
		body: JSON.stringify(data)
	};

	return await fetch(url, options).then(response => {
		return response.json() as Promise<ResponseObject<T>>
	})
}

export async function del<T> (
	url: string | URL,
	headers: { [key: string]: any } = {}
) {
	const options = {
		headers: withHeaders(headers),
		method: 'DELETE'
	};

	return await fetch(url, options).then(response => {
		return response.json() as Promise<ResponseObject<T>>
	})
}

export function useQuery<
	TResponse,
	TError extends Error = Error
> (fetcher: (params: QueryParams) => Promise<ResponseObject<TResponse>>,
   params?: QueryParams
) {
	const [busy, setBusy] = useState<boolean>(false);
	const [error, setError] = useState<TError>(null)
	const [response, setResponse] = useState<ResponseObject<TResponse>>({
		status: ResponseStatus.INITIAL,
		data: null
	});

	const fetchCallback = async (overrideParams?: QueryParams) => {
		setBusy(() => true);

		try {
			const queryParams = { ...params, ...overrideParams };
			const data = await Promise.resolve(fetcher(queryParams));

			setBusy(() => false);
			setResponse(() => data as ResponseObject<TResponse>);
		} catch (ex) {
			setError(() => ex as TError);
			setBusy(() => false);
		}
	};

	const reset = () => {
		setResponse({
			status: ResponseStatus.INITIAL,
			data: null
		});
	}

	return useMemo(() => ({
		reset,
		busy,
		error,
		response,
		callback: fetchCallback
	}), [response, fetchCallback]);
}
