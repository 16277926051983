// ** Type import
import {
	VerticalNavItemsType,
	NavSectionTitle,
} from "@/@materialize/layouts/types";

const managementMenus: VerticalNavItemsType = [
	{
		title: "Sản phẩm",
		icon: "ri:shopping-bag-2-line",
		children: [
			{
				title: "Danh mục sản phẩm",
				path: "/categories",
			},
			{
				title: "Danh sách sản phẩm",
				path: "/products",
			},
			{
				title: "Đánh giá",
				path: "/products/reviews",
			},
		],
	},
	{
		title: "Nhà cung cấp",
		icon: "ri:store-2-line",
		children: [
			{
				title: "Danh sách NCC",
				path: "/suppliers",
			},
			{
				title: "Phân loại NCC",
				path: "/suppliers/categories",
			},
		],
	},
	{
		title: "Đấu giá",
		icon: "ri:auction-line",
		children: [
			{
				title: "Danh sách đấu giá",
				path: "/auctions",
			},
			{
				title: "Báo cáo",
				path: "/auctions/report",
			},
			{
				title: "Cài đặt",
				path: "/auctions/settings",
			},
		],
	},
	{
		title: "Đơn hàng",
		icon: "ri:pages-line",
		children: [
			{
				title: "Danh sách đơn hàng",
				path: "/orders",
			},
			{
				title: "Giao hàng",
				path: "/orders/delivery",
			},
		],
	},
	{
		title: "Khách hàng",
		icon: "ri:team-line",
		children: [
			{
				title: "Danh sách khách hàng",
				path: "/customers",
			},
			{
				title: "Tặng lượt bids",
				path: "/customers/give-away",
			},
		],
	},
	{
		title: "Người giới thiệu",
		icon: "ri:user-voice-line",
		path: "/referrals",
	},
];

const contentMenus: VerticalNavItemsType = [
	{
		title: "Tin tức",
		icon: "ri:newspaper-line",
		children: [
			{
				title: "Danh sách bài viết",
				path: "/posts",
			},
			{
				title: "Thêm bài viết",
				path: "/posts/add",
			},
		],
	},
	{
		title: "Hình ảnh / Banner",
		icon: "ri:image-line",
		children: [
			{
				title: "Danh sách banner",
				path: "/posts",
			},
			{
				title: "Thêm banner",
				path: "/posts/add",
			},
		],
	},
];

const systemMenus: VerticalNavItemsType = [
	{
		title: "Tài khoản quản trị",
		icon: "ri:user-3-line",
		children: [
			{
				title: "Danh sách tài khoản",
				path: "/users",
			},
			{
				title: "Phân quyền",
				path: "/permissions",
			},
		],
	},
];

const makeSectionMenu = (sectionTitle: string): NavSectionTitle => ({
	sectionTitle,
});

const navigation = (): VerticalNavItemsType => {
	const dashboardMenu = {
		title: "Dashboard",
		path: "/",
		icon: "ri:home-3-line",
	};

	const logoutMenu = {
		title: "Logout",
		icon: "ri:logout-box-r-line",
		path: "/logout",
	};

	return [
		dashboardMenu,
		makeSectionMenu("Quản trị"),
		...managementMenus,
		makeSectionMenu("Nội dung"),
		...contentMenus,
		makeSectionMenu("Hệ thống"),
		...systemMenus,
		logoutMenu,
	];
};

export default navigation;
