import { Category } from '../entities/Category';
import {
	DataGrid,
	GridColDef,
	GridRenderCellParams,
	GridSelectionModel
} from '@mui/x-data-grid';
import { Stack } from '@mui/material';
import React, { ReactNode } from 'react';
import {
	ResponseObject
} from '@/@core/entities/Http';
import {
	dataGridOptions,
	makeActionsColumn,
	makeAuthorColumn,
	makeIdColumn
} from '@/@core/helpers/DataGrid';
import { GridCallbackDetails } from '@mui/x-data-grid/models/api';
import { GridInputSelectionModel } from '@mui/x-data-grid/models/gridSelectionModel';
import IconButton from '@mui/material/IconButton';
import { Icon } from '@iconify/react';
import Chip from '@mui/material/Chip';
import icons from '@/config/icons';
import DeleteButton from '@/@core/components/DeleteButton';
import { Link } from 'react-router-dom';
import { client } from '@/modules/Category/services/CategoryService';

export interface CategoryTableProps {
	children?: ReactNode
	dataSource: ResponseObject<Category[]>
	selectedEntries: GridInputSelectionModel
	onSelectionChanged: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => void
}

const renderCategoryInfo = ({ value, row }: GridRenderCellParams) : ReactNode => (
	<Stack>
		<strong>{value}</strong>
		<em>{row.alias}</em>
	</Stack>
);

const renderCategoryStatus = ({ value }: GridRenderCellParams): ReactNode => {
	return value === 'ACTIVE'
		? <Chip label="Active" size="small" color="success" variant='outlined' />
		: <Chip label="Inactive" size="small" color='secondary' variant='outlined' />
}

const buttonEdit = ({ row }) => (
	<IconButton component={Link} to={`/categories/${row.id}`} key={`edit-button-${row.id}`}>
		<Icon icon={icons.EDIT} />
	</IconButton>
);

const buttonDelete = ({ row }) => (
	<DeleteButton
		item={row}
		confirmMessage={() => `Bạn có chắc chắn muốn xóa danh mục "${row.name}" không?`}
		successMessage={() => `Đã xóa danh mục "${row.name}" thành công!`}
		errorMessage={() => `Không thể xóa danh mục "${row.name}"!`}
		remover={client.removeItem}
		key={`delete-button-${row.id}`}
	/>
);

export default function CategoryTable ({ dataSource, selectedEntries, onSelectionChanged }: CategoryTableProps) {
	const actions = [
		buttonEdit,
		buttonDelete
	];

	/**
	 * Data grid columns definitions
	 */
	const gridColumns: GridColDef[] = [
		makeIdColumn(), {
			field: 'name',
			headerName: 'Tên',
			flex: 1,
			renderCell: renderCategoryInfo
		}, {
			field: 'status',
			headerName: 'Trạng thái',
			width: 160,
			renderCell: renderCategoryStatus
		},
		makeAuthorColumn(),
		makeActionsColumn(actions)
	];

	const gridOptions = dataGridOptions({
		columns: gridColumns,
		rows: dataSource.data || [],
		selectionModel: selectedEntries,
		onSelectionModelChange: onSelectionChanged
	})

	return (
		<DataGrid {...gridOptions} />
	)
}
