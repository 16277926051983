import {loader as rolesLoader} from "@/modules/Role/pages";
import Permissions from "@/modules/Role/pages";

export default function permissionRoutes() {
	return [{
		path: 'permissions',
		title: 'Phân ',
		loader: rolesLoader,
		element: <Permissions />,
	}];
}
