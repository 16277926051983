import React, {useEffect} from "react";
import {Box, Button, FormGroup, Modal} from "@mui/material";
import {useLoaderData} from "react-router-dom";
import {UserLoaderResult} from "@/modules/User/pages";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {post} from "@/@core/services/HttpService";
import Role from "@/modules/Role/entities/Role";

const style = {
	position: 'absolute' as 'absolute',
	top: '45%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: "85%",
	bgcolor: 'background.paper',
	boxShadow: 24,
	borderRadius: 1,
	pt: 2,
	px: 4,
	pb: 3,
};

export default function UserRoleModal(data) {
	const userData = useLoaderData() as UserLoaderResult;
	const [open, setOpen] = React.useState(false);
	const [roles, setRoles] = React.useState([]);
	const [currentRole, setRole] = React.useState({});

	useEffect(() => {
		userData.roles.then(res => {
			setRoles(res.data);
		})
	}, [userData.roles])

	const handleOpen = async () => {
		let userRole = {};
		data.data.row.roles.map(r=>{
			userRole = {...userRole,[r.name]: r.id}
		})
		setRole(userRole);
		setOpen(true);
	};
	const handleClose = () => {
		setRole({});
		setOpen(false);
	};

	const onChange = (e) => {
		if(e.target.checked === false){
			delete currentRole[e.target.name];
			setRole({...currentRole});
		} else {
			setRole({...currentRole, [e.target.name]: e.target.value});
		}
	}

	const onSubmit = async () => {
		const arr = [];
		for (const prop in currentRole) {
			if (currentRole.hasOwnProperty(prop)) {
				arr.push(currentRole[prop])
			}
		}
		const reqData = {
			id: data.data.id,
			roles: arr
		}

		const storedToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
		await post(`${process.env.REACT_APP_API_ENDPOINT}roles/user_roles`,
			reqData,
			{"Authorization": `Bearer ${storedToken}` });
		setOpen(false);
	}

	return (<>
		<Button onClick={handleOpen}>Sửa quyền</Button>
		<Modal
			hideBackdrop
			open={open}
			onClose={handleClose}
			aria-labelledby="child-modal-title"
			aria-describedby="child-modal-description"
		>
			<Box sx={{...style, width: "85%"}}>
				<h2 id="child-modal-title">Phân quyền user: {data.data.row.nickName}</h2>
				<FormGroup>
					{roles.map((role, i) => {
						return role.name && <FormControlLabel
							className={"col-2"}
							key={i}
							value={role.id}
							control={<Checkbox color="primary" checked={currentRole[role.name] == role.id} onChange={onChange}
																 name={role.name} value={role.id}/>}
							label={role?.description}
							labelPlacement="end"
						/>
					})}
				</FormGroup>
				<div style={{marginTop: 15}}>
					<Button variant="contained" color="primary" onClick={onSubmit} style={{marginRight: 5}}>Xác nhận</Button>
					<Button variant="contained" color="secondary" onClick={handleClose}>Hủy</Button>
				</div>
			</Box>
		</Modal>
	</>)
}
