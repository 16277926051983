import { Customers } from "../entities/Customers";
import {
	DataGrid,
	GridColDef,
	GridRenderCellParams,
	GridSelectionModel,
} from "@mui/x-data-grid";
import { Stack, Avatar } from "@mui/material";
import React, { ReactNode } from "react";
import { ResponseObject } from "@/@core/entities/Http";
import {
	dataGridOptions,
	makeIdColumn,
} from "@/@core/helpers/DataGrid";
import { GridCallbackDetails } from "@mui/x-data-grid/models/api";
import { GridInputSelectionModel } from "@mui/x-data-grid/models/gridSelectionModel";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@iconify/react";
import Chip from "@mui/material/Chip";
import icons from "@/config/icons";
import DeleteButton from "@/@core/components/DeleteButton";
import { Link } from "react-router-dom";
import { client } from "../services/CustomersService";

export interface CustomersTableProps {
	children?: ReactNode;
	dataSource: ResponseObject<Customers[]>;
	selectedEntries: GridInputSelectionModel;
	onSelectionChanged: (
		selectionModel: GridSelectionModel,
		details: GridCallbackDetails
	) => void;
}

const renderCustomerInfo = ({
	value,
	row,
}: GridRenderCellParams): ReactNode => (
	<Stack>
		<strong>{value ? value : "N/A"}</strong>
		<em>{row.alias}</em>
	</Stack>
);

const renderCustomerStatus = ({ value }: GridRenderCellParams): ReactNode => {
	return value === "ACTIVE" ? (
		<Chip label="Active" size="small" color="success" variant="outlined" />
	) : (
		<Chip label="Inactive" size="small" color="secondary" variant="outlined" />
	);
};

const buttonEdit = ({ row }) => (
	<IconButton
		component={Link}
		to={`/customers/${row.id}`}
		key={`edit-button-${row.id}`}
	>
		<Icon icon={icons.EDIT} />
	</IconButton>
);

const buttonDelete = ({ row }) => (
	<DeleteButton
		item={row}
		confirmMessage={() =>
			`Bạn có chắc chắn muốn xóa danh mục "${row.name}" không?`
		}
		successMessage={() => `Đã xóa danh mục "${row.name}" thành công!`}
		errorMessage={() => `Không thể xóa danh mục "${row.name}"!`}
		remover={client.removeItem}
		key={`delete-button-${row.id}`}
	/>
);

export default function CustomersTable({
	dataSource,
	selectedEntries,
	onSelectionChanged,
}: CustomersTableProps) {
	const actions = [buttonEdit, buttonDelete];

	/**
	 * Data grid columns definitions
	 */
	const gridColumns: GridColDef[] = [
		makeIdColumn(),
		{
			field: "avatar",
			headerName: "Tên",
			flex: 1,
			width: 50,
			renderCell: ({ row }) => (
				<Avatar
					alt={row.name}
					src={
						row.avatar
							? row.avatar
							: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
					}
				/>
			),
		},
		{
			field: "nickName",
			headerName: "Tên",
			flex: 1,
			renderCell: renderCustomerInfo,
		},
		{
			field: "email",
			headerName: "Email",
			flex: 1,
			renderCell: renderCustomerInfo,
		},
		{
			field: "phone",
			headerName: "Điện thoại",
			flex: 1,
			renderCell: renderCustomerInfo,
		},
		{
			field: "bidTurn",
			headerName: "Lượt đấu giá",
			flex: 1,
			renderCell: renderCustomerInfo,
		},
		{
			field: "status",
			headerName: "Trạng thái",
			width: 160,
			renderCell: renderCustomerStatus,
		},
	];

	const gridOptions = dataGridOptions({
		columns: gridColumns,
		rows: dataSource.data || [],
		selectionModel: selectedEntries,
		onSelectionModelChange: onSelectionChanged,
	});

	return <DataGrid {...gridOptions} />;
}
