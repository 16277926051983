import { Icon } from "@iconify/react";
import { Box, Button, Stack } from "@mui/material";
import { GridInputSelectionModel } from "@mui/x-data-grid/models/gridSelectionModel";
import icons from "@/config/icons";
import { useSearchParams } from "react-router-dom";
import {
	FieldValues,
	FormContainer,
	SelectElement,
	TextFieldElement,
} from "react-hook-form-mui";
import React from "react";
import { LoadingButton } from "@mui/lab";

export interface CustomersTableHeaderProps {
	selectedEntries: GridInputSelectionModel;
}

const statusOptions = [
	{
		id: "ALL",
		label: "All",
	},
	{
		id: "ACTIVE",
		label: "Active",
	},
	{
		id: "DEACTIVE",
		label: "Inactive",
	},
];

export default function CustomersTableHeader({
	selectedEntries,
}: CustomersTableHeaderProps) {
	const [searchParams, setSearchParams] = useSearchParams({
		keyword: "",
		status: "ALL",
	});

	const handleFilterChange = (data: FieldValues) => {
		setSearchParams((prev) => ({
			...prev,
			...data,
		}));
	};

	return (
		<Box
			sx={{
				p: 5,
				pb: 5,
				display: "flex",
				flexWrap: "wrap",
				alignItems: "center",
				justifyContent: "space-between",
			}}
		>
			<FormContainer
				defaultValues={Object.fromEntries(searchParams)}
				onSuccess={handleFilterChange}
			>
				<Stack direction="row" spacing={2}>
					<TextFieldElement size="small" label="Tìm kiếm" name="keyword" />
					<SelectElement
						size="small"
						name="status"
						label="Trạng thái"
						options={statusOptions}
					/>
					<Button
						type="submit"
						variant="outlined"
						startIcon={<Icon icon={icons.SEARCH} />}
					>
						Tìm kiếm
					</Button>
				</Stack>
			</FormContainer>

			<Stack direction="row" spacing={2}>
				<LoadingButton
					disabled={selectedEntries === 0}
					startIcon={<Icon icon={icons.DELETE} />}
					variant="contained"
					color="error"
				>
					Xóa khách hàng
				</LoadingButton>

				{/*<Button startIcon={<Icon icon={icons.EXPORT} />}>Xuất ra excel</Button>*/}
			</Stack>
		</Box>
	);
}
