import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import getRoutesConfig from '@/config/routes';
import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';

const routeConfig = getRoutesConfig();
const router = createBrowserRouter([{
    path: '*',
    element: <App />,
    children: routeConfig
}]);
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
