
import {
	Await,
	defer,
	useLoaderData,
	Outlet,
	Link
} from 'react-router-dom';
import React, {
	ReactElement,
	Suspense,
	useState
} from 'react';
import { Order } from '@/modules/Order/entities/Order';
import {
	Button,
	Card,
	CircularProgress
} from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import PageHeader from '@/layouts/components/PageHeader';
import icons from '@/config/icons';
import { ResponseObject } from '@/@core/entities/Http';
import { Icon } from '@iconify/react';
import OrderTableHeader from '@/modules/Order/components/OrderTableHeader';
import OrderTable from '@/modules/Order/components/OrderTable';
// import { Order } from '../entities/Order';
import { client } from '../services/OrderService';

export type OrderLoaderResult = {
	orders: Promise<ResponseObject<Order[]>>
}

export const loader = ({ request }) => {
	const url = new URL(request.url);
	return defer({
		orders: client.fetchItems(Object.fromEntries(url.searchParams))
	});
}

export default function OrderList() {
	const data = useLoaderData() as OrderLoaderResult;
	const [selectedOrders, setSelectedOrders] = useState<GridSelectionModel>([]);

	const actions: ReactElement[] = [
		// <Button
		// 	component={Link}
		// 	startIcon={<Icon icon={icons.ADD} />}
		// 	to="add"
		// 	relative="path"
		// 	key="button-add-category"
		// 	variant="contained">
		// 	Thêm danh mục
		// </Button>
		<></>
	]

	return (
		<>
			<PageHeader actions={actions} />
			<Card>
				<OrderTableHeader selectedEntries={selectedOrders} />
				<Suspense fallback={<CircularProgress />}>
					<Await
						resolve={data.orders}
						children={(response) => (
							<OrderTable
								dataSource={response}
								selectedEntries={selectedOrders}
								onSelectionChanged={setSelectedOrders}
							/>
						)
						}
					/>
				</Suspense>
			</Card>
			<Outlet />
		</>
	)
}
