import {
	api,
	del,
	get,
	post,
	put
} from '@/@core/services/HttpService';
import _ from 'lodash';
import { ResponseObject } from '@/@core/entities/Http';
import { Category } from '@/modules/Category/entities/Category';
import CategoryContent from '@/modules/Category/entities/CategoryContent';

export type FilterBuilderParams = {
	url: URL,
	params: {
		[key: string]: any
	}
}

export type ApiHooks<TEntity> = {
	prepareDataForSave?: (data: TEntity) => TEntity;
}

export interface AbstractEntity {
	id: number
}

export interface LocalizationContent {
	localeCode: string
	[key: string]: any
}
export interface LocalizationEntity {
	contents: LocalizationContent[]
}

export function prepareLocalizationData<TEntity extends LocalizationEntity>(data: TEntity) {
	return {
		...data,
		contents: Object.values(
			_.mapValues(data.contents, (value, key) => {
				return {
					...value,
					localeCode: key
				}
			})
		)
	}
}

export function prepareLocalizationFormData<TEntity extends LocalizationEntity>(data: TEntity) {
	return {
		...data,
		contents: data.contents.reduce((prev: { [key: string]: LocalizationContent }, content) => {
			prev[content.localeCode] = content;
			return prev;
		}, {})
	}
}

/**
 * Generate api client for a resource
 *
 * @param base
 * @param filterBuilder
 * @param hooks
 */
export default function createClient<TEntity extends AbstractEntity>(
	base: string,
	filterBuilder: (params: FilterBuilderParams) => URL,
	hooks: ApiHooks<TEntity> = {}
) {
	function fetchItems (params: {
		[key: string]: any
	}) {
		return get<TEntity[]>(filterBuilder({
			url: api(base),
			params
		}));
	}

	function fetchItem (id: number): Promise<ResponseObject<TEntity>> {
		return get<TEntity>(api(`${base}/${id}`))
	}

	function saveItem (data: TEntity): Promise<ResponseObject<TEntity>> {
		const submitData = hooks.prepareDataForSave
			? hooks.prepareDataForSave(data)
			: data;

		if (data.id) {
			return put<TEntity>(api(`${base}/${data.id}`), submitData)
		}

		return post<TEntity>(api(base), submitData);
	}

	function removeItem (data: TEntity) {
		return del<TEntity>(api(`${base}/${data.id}`));
	}

	return {
		fetchItems,
		fetchItem,
		saveItem,
		removeItem
	}
}
