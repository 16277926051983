import {
	ReactElement,
	ReactNode,
	Suspense
} from 'react';
import {
	Backdrop,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle
} from '@mui/material';
import { ResponseObject } from '@/@core/entities/Http';
import {
	Await,
	useLoaderData
} from 'react-router-dom';

type AsyncFormDialogResult = {
	formData: Promise<ResponseObject<any>>
	[key: string]: Promise<ResponseObject<any>>
}

export default function AsyncFormDialog ({ title, children }) {
	const asyncResult = useLoaderData() as AsyncFormDialogResult;

	return (
		<Suspense
			fallback={(
				<Backdrop open={true} sx={{ zIndex: 1500 }}>
					<CircularProgress />
				</Backdrop>
			)}
			children={(
				<Await
					resolve={asyncResult.formData}
					children={(response) => (
						<Dialog open={true}>
							<DialogTitle>{title(response)}</DialogTitle>
							<DialogContent children={children(response)} />
						</Dialog>
					)}
				/>
			)}
		/>
	);
}
