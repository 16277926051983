import PageHeader from "@/layouts/components/PageHeader";
import React, {Suspense, useEffect, useState} from "react";
import {Card, CircularProgress, FormGroup} from "@mui/material";
import {Await, defer, Outlet, useLoaderData} from "react-router-dom";
import {GridSelectionModel} from "@mui/x-data-grid";
import {ResponseObject} from "@/@core/entities/Http";
import {client} from "@/modules/Role/services/RoleServices";
import Role from "@/modules/Role/entities/Role";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export type RoleLoaderResult = {
	roles: Promise<ResponseObject<Role[]>>
}

export const loader = ({request}) => {
	const url = new URL(request.url);
	return defer({
		roles: client.fetchItems(Object.fromEntries(url.searchParams))
	});
}

export default function Permissions() {
	const data = useLoaderData() as RoleLoaderResult;
	const [selected, setSelected] = useState<GridSelectionModel>([]);
	const listRole = [];

	const onChange = (e) => {
		if (e.target.checked) {
			listRole.push(e.target.value)
		} else {
			listRole.splice(listRole.indexOf(e.target.value), 1)
		}
		console.log(listRole)
	}

	return (
		<>
			<PageHeader actions={[]}/>
			<Card>
				{/*<CategoryTableHeader selectedEntries={selectedCategories} />*/}
				<Suspense fallback={<CircularProgress/>}>
					<Await
						resolve={data.roles}
						children={(response) => {
							return (
								<>
									<div style={{padding: "1.5rem"}}><strong>Phân Quyền</strong></div>
									<div style={{padding: "0 1.5rem 1.5rem"}} className={"row"}>
										{response.data && response.data.map((e, i) => {
											return e.name && <FormControlLabel
												className={"col-2"}
												key={i}
												value={e.id}
												control={<Checkbox color="primary" onChange={onChange} name={e.id.toString()}/>}
												label={e?.description}
												labelPlacement="end"
											/>
										})}
									</div>
									<button>click me baybe</button>

								</>
							)
						}}
					/>
				</Suspense>
			</Card>
			<br/>
			<Card>
				{/*<CategoryTableHeader selectedEntries={selectedCategories} />*/}
				<Suspense fallback={<CircularProgress/>}>
					<div style={{padding: 50}}>andkjahdjkahdjkahdkjashdkjahdjkashd</div>
				</Suspense>
			</Card>
			<Outlet/>
		</>
	)
}
