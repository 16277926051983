import React, { useEffect } from 'react';

import { Icon } from '@iconify/react';
import {
	QueryParams,
	useQuery
} from '@/@core/services/HttpService';
import {
	ResponseObject,
	ResponseStatus
} from '@/@core/entities/Http';
import { CircularProgress } from '@mui/material';

import icons from '@/config/icons';
import IconButton from '@mui/material/IconButton';
import useConfirmDialog from '@/@core/hooks/useConfirmDialog';
import toast from 'react-hot-toast';
import { useRevalidator } from 'react-router-dom';

export type DeleteButtonProps<T> = {
	item: T
	confirmMessage: (data: T) => string
	successMessage: (data: T) => string
	errorMessage: (data: T) => string
	onSuccess?: (item: T) => void
	remover: (params: QueryParams) => Promise<ResponseObject<T>>
}

export default function DeleteButton<T> ({ item, confirmMessage, successMessage, errorMessage, onSuccess, remover }: DeleteButtonProps<T>) {
	const { showConfirm, renderDialog } = useConfirmDialog();
	const { response, error, busy: removing, callback: remove } = useQuery(remover);
	const { revalidate } = useRevalidator();

	useEffect(() => {
		revalidate();

		if (response.status !== ResponseStatus.INITIAL) {
			response.status === ResponseStatus.SUCCESS
				? toast.success(successMessage(item))
				: toast.error(errorMessage(item));

			onSuccess && onSuccess(item);
		}

		if (error !== null) {
			console.log(error);
			toast.error(error.message)
		}
	}, [error, response])

	const handleClick = () => {
		showConfirm({
			message: confirmMessage(item),
			onConfirmed: () => remove(item)
		})
	}

	return (
		<>
			<IconButton onClick={handleClick} disabled={removing}>
				{removing && <CircularProgress size={24} />}
				{!removing && <Icon icon={icons.DELETE} />}
			</IconButton>

			{/* Confirmation dialog */}
			{renderDialog()}
		</>
	)
}
