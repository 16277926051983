import OrderList, { loader as orderLoader } from '@/modules/Order/pages';

export default function orderRoutes() {
	return [{
		path: 'orders',
		title: 'Danh sách đơn hàng',
		loader: orderLoader,
		element: <OrderList />,
		// children: [{
		// 	path: ':categoryId',
		// 	loader: categoryLoader,
		// 	element: (
		// 		<AsyncFormDialog
		// 			title={(response) => response.data.id ? 'Chỉnh sửa danh mục' : 'Thêm mới danh mục'}
		// 			children={(response) => (
		// 				<CategoryForm formData={response.data} />
		// 			)}
		// 		/>
		// 	)
		// }]
	}];
}
