import {
	gridClasses,
	GridColDef,
	GridRenderCellParams
} from '@mui/x-data-grid';
import React, {
	ReactElement,
	ReactNode
} from 'react';
import { Stack } from '@mui/material';
import { defaultsDeep } from 'lodash/fp';

export type DataGridAction = (params: GridRenderCellParams) => ReactElement

export function makeIdColumn (overrides: GridColDef = null): GridColDef {
	return {
		field: 'id',
		headerName: 'ID',
		width: 50,
		...overrides,
	};
}

export function makeAuthorColumn (overrides: GridColDef = null): GridColDef {
	return {
		field: 'createdBy',
		headerName: 'Người tạo',
		width: 160,
		renderCell: ({ value, row }: GridRenderCellParams): ReactNode => (
			<Stack>
				<strong>{value?.nickName}</strong>
				<em>{row.createdAt}</em>
			</Stack>
		),

		...overrides
	};
}

export function makeActionsColumn (actions: DataGridAction[], overrides: GridColDef = null): GridColDef {
	return {
		field: 'actions',
		headerName: '',
		width: 160,
		...overrides,
		renderCell: (params: GridRenderCellParams): ReactNode => (
			<Stack direction="row" spacing={2}>
				{actions.map(action => action(params))}
			</Stack>
		),
	};
}

export const dataGridOptions = defaultsDeep({
	checkboxSelection: true,
	disableColumnFilter: true,
	autoHeight: true,
	disableSelectionOnClick: true,
	getRowHeight: () => 'auto',
	sx: {
		[`& .${gridClasses.cell}`]: {
			py: 1
		}
	}
})
