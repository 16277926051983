import { isEmpty } from "lodash";
import { omit } from "lodash";
import createClient, {
	FilterBuilderParams,
	prepareLocalizationData,
} from "@/@core/services/ApiService";
import { Customers } from "../entities/Customers";

/**
 * Making query strings to filter data from APIs with the aid of a helper function
 *
 * @param url
 * @param params
 */
const filterBuilder = ({ url, params }: FilterBuilderParams) => {
	if (!isEmpty(params.keyword)) {
		url.searchParams.append("filter", `contents.name:[LIKE,${params.keyword}]`);
	}

	if (!isEmpty(params.status) && params.status !== "ALL") {
		url.searchParams.append("filter", `status:[EQUALS,${params.status}]`);
	}
	return url;
};

const hooks = {
	/**
	 * Getting data ready to submit to the backend for saving
	 * @param data
	 */
	prepareDataForSave: (data: Customers) => {
		return prepareLocalizationData<Customers>(
			omit(data, ["createdBy", "createdAt"])
		);
	},
};

export const client = createClient<Customers>(
	"users",
	filterBuilder,
	hooks
);
