import {
	Box,
	Breadcrumbs,
	Button,
	Stack,
	Typography
} from '@mui/material';
import {
	Link,
	useLocation,
	useMatches,
} from 'react-router-dom';
import { Icon } from '@iconify/react';
import React, { ReactElement } from 'react';
import navigation from '@/config/navigation';
import {
	NavGroup,
	NavLink,
	VerticalNavItemsType
} from '@/@materialize/layouts/types';

export type PageHeaderButtonProps = {
	title: string
	variant?: "text" | "outlined" | "contained"
	color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning"
	icon?: string
	handler: () => void
}
export type PageHeaderProps = {
	actions?: PageHeaderButtonProps[]
}

function getPaths (location: string, navItems: VerticalNavItemsType) {
	return navItems.filter(navItem => 'children' in navItem)
		.reduce((paths, navItem: NavGroup) => {
			const matchLocation = navItem.children.find((child: NavLink) => child.path === location);

			if (matchLocation != null) {
				paths.push(navItem);
				paths.push(matchLocation)
			}

			return paths;
		}, [])
}

export default function PageHeader ({ actions }: { actions: ReactElement[] }) {
	const location = useLocation();
	const navItems = navigation();

	let paths = getPaths(location.pathname, navItems);
	let currentPath = paths.pop() as NavLink;

	while (currentPath === undefined) {
		paths = getPaths(location.pathname.substring(0, location.pathname.lastIndexOf('/')), navItems);
		currentPath = paths.pop() as NavLink;
	}

	const buttonIcon = (icon: string) => (<Icon icon={icon} />)
	return (
		<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '24px' }}>
			<Stack spacing={2}>
				<Breadcrumbs aria-label="breadcrumb">
					<Link to='/' style={{ color: 'inherit' }}>
						<Icon icon="ri:home-3-line" style={{ fontSize: '20px', verticalAlign: 'text-bottom' }} />
					</Link>
					{paths.map(path => (
						<Typography color="inherit" key={path}>
							{ path.title }
						</Typography>
					))}
				</Breadcrumbs>
				<Typography variant="h5">{ currentPath.title }</Typography>
			</Stack>

			{actions.length > 0 && (
				<Stack spacing={2} direction="row">
					{actions}
				</Stack>
			)}
		</Box>
	);
}
