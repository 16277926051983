export enum ResponseStatus {
    INITIAL = "INITIAL",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR"
}

export interface ResponsePaginate {
    totalPages: number,
    totalItems: number,
    currentPage: number
}

export interface ResponseObject<T> {
    status: ResponseStatus,
    data: T,
    paginate?: ResponsePaginate
}