import { Order } from '../entities/Order';
import {
	DataGrid,
	GridColDef,
	GridRenderCellParams,
	GridSelectionModel
} from '@mui/x-data-grid';
import { Stack } from '@mui/material';
import React, { ReactNode } from 'react';
import {
	ResponseObject
} from '@/@core/entities/Http';
import {
	dataGridOptions,
	makeActionsColumn,
	makeAuthorColumn,
	makeIdColumn
} from '@/@core/helpers/DataGrid';
import { GridCallbackDetails } from '@mui/x-data-grid/models/api';
import { GridInputSelectionModel } from '@mui/x-data-grid/models/gridSelectionModel';
import IconButton from '@mui/material/IconButton';
import { Icon } from '@iconify/react';
import Chip from '@mui/material/Chip';
import icons from '@/config/icons';
import DeleteButton from '@/@core/components/DeleteButton';
import { Link } from 'react-router-dom';
import { client } from '../services/OrderService';

export interface OrderTableProps {
	children?: ReactNode
	dataSource: ResponseObject<Order[]>
	selectedEntries: GridInputSelectionModel
	onSelectionChanged: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => void
}

const renderProductInfo = ({ value, row }: GridRenderCellParams): ReactNode =>
(
	<Stack>
		<strong>{value}</strong>
		<em>{row.asset.name}</em>
	</Stack>
);

const renderWinner = ({ value, row }: GridRenderCellParams): ReactNode => (
	<Stack>
		<em>{row.winner.nickName}</em>
	</Stack>
);

const renderProductName = ({ value, row }: GridRenderCellParams): ReactNode => (
	<Stack>
		<strong>{row.asset.name}</strong>
	</Stack>
);

const renderCreateAt = ({ value, row }: GridRenderCellParams): ReactNode => (
	<Stack>
		<strong>{row.createdAt}</strong>
	</Stack>
);

const renderOrderStatus = ({ value, row }: GridRenderCellParams): ReactNode => (
	<Stack>
		<strong>{row.status}</strong>
	</Stack>
)

const buttonEdit = ({ row }) => (
	<IconButton component={Link} to={`/categories/${row.id}`} key={`edit-button-${row.id}`}>
		<Icon icon={icons.EDIT} />
	</IconButton>
);

const buttonDelete = ({ row }) => (
	<DeleteButton
		item={row}
		confirmMessage={() => `Bạn có chắc chắn muốn xóa danh mục "${row.name}" không?`}
		successMessage={() => `Đã xóa danh mục "${row.name}" thành công!`}
		errorMessage={() => `Không thể xóa danh mục "${row.name}"!`}
		remover={client.removeItem}
		key={`delete-button-${row.id}`}
	/>
);

export default function OrderTable({ dataSource, selectedEntries, onSelectionChanged }: OrderTableProps) {
	const actions = [
		buttonEdit,
		buttonDelete
	];

	/**
	 * Data grid columns definitions
	 */
	const gridColumns: GridColDef[] = [
		makeIdColumn(),
		{
			field: 'images',
			headerName: 'Người thắng',
			flex: 1,
			renderCell: renderWinner
		},
		{
			field: 'name',
			headerName: 'Tên sản phẩm',
			flex: 1,
			renderCell: renderProductName
		},
		// {
		// 	field: 'supplier',
		// 	headerName: 'Nhà cung cấp',
		// 	width: 160,
		// 	renderCell: renderOrderStatus
		// },
		{
			field: 'winTime',
			headerName: 'Thời gian tạo',
			width: 160,
			renderCell: renderCreateAt
		},
		{
			field: 'status',
			headerName: 'Trạng thái',
			width: 160,
			renderCell: renderOrderStatus
		},
		makeActionsColumn(actions)
	];

	const gridOptions = dataGridOptions({
		columns: gridColumns,
		rows: dataSource.data || [],
		selectionModel: selectedEntries,
		onSelectionModelChange: onSelectionChanged
	})

	return (
		<DataGrid {...gridOptions} />
	)
}
