import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState
} from 'react';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import {
	useLocation,
	useSearchParams
} from 'react-router-dom';

type User = {
	email: string
	avatar: string
	roles: string[]
	exp: number
}

export enum AuthStatus {
	INITIAL = 'INITIAL',
	FILLED = 'FILLED'
}

export type AuthValue = {
	status: AuthStatus
	user: User
	login: () => void
	logout: () => void
}

const defaultValues: AuthValue = {
	status: AuthStatus.INITIAL,
	user: null,
	login: () => Promise.resolve(),
	logout: () => Promise.resolve()
};

const AuthContext = createContext(defaultValues);

export function useAuth () {
	return useContext(AuthContext);
}

export default function AuthProvider ({ children }: { children: ReactNode }) {
	const [user, setUser] = useState<User>(defaultValues.user);
	const [status, setStatus] = useState<AuthStatus>(AuthStatus.INITIAL);
	const [params] = useSearchParams();
	const location = useLocation();

	useEffect(() => {
		if (params.get("token")) {
			localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, params.get("token"));
			return window.location.assign(location.pathname);
		}

		const initAuth = () => {
			const storedToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);

			if (storedToken) {
				const decodedData = _.pick(jwtDecode(storedToken), [
					'avatar',
					'email',
					'roles',
					'exp'
				]);

				setUser((prevState) => ({
					...prevState,
					...decodedData
				}));
			}

			setStatus(() => AuthStatus.FILLED);
		}

		initAuth();
	}, [])

	const login = () => {
		window.location.assign(
			new URL(process.env.REACT_APP_AUTH_ENDPOINT)
		);
	}

	const logout = () => {
		localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
		setUser(() => null);
	}

	return <AuthContext.Provider
		value={{
			status,
			user,
			login,
			logout
		}}
		children={children}
	/>
}
