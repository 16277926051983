import { styled } from '@mui/material/styles';
import {
	TabList,
	TabListProps
} from '@mui/lab';

const PilledTabList = styled(TabList)<TabListProps>(({ theme }) => ({
	'& .MuiTabs-indicator': {
		display: 'none'
	},
	'& .Mui-selected': {
		backgroundColor: theme.palette.primary.main,
		color: `${theme.palette.common.white} !important`
	},
	'& .MuiTab-root': {
		minHeight: 38,
		minWidth: 110,
		borderRadius: 6,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	}
}))

export default PilledTabList;