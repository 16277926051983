const icons = {
	SEARCH: 'ri:search-line',
	EXPORT: 'ri:file-download-line',
	DELETE: 'ri:delete-bin-line',
	ADD: 'ri:add-line',
	EDIT: 'ri:pencil-line',
	CHECK: 'ri:check-line',
	ARROW_LEFT: 'ri:arrow-left-line'
}

export default icons;