import React, {ReactNode} from "react";
import {ResponseObject} from "@/@core/entities/Http";
import {Category} from "@/modules/Category/entities/Category";
import {GridInputSelectionModel} from "@mui/x-data-grid/models/gridSelectionModel";
import {DataGrid, GridColDef, GridSelectionModel} from "@mui/x-data-grid";
import {GridCallbackDetails} from "@mui/x-data-grid/models/api";
import {dataGridOptions, makeIdColumn} from "@/@core/helpers/DataGrid";
import Chip from "@mui/material/Chip";
import UserRoleModal from "@/modules/User/components/UserRoleModal";

export interface UserTableProps {
	children?: ReactNode
	dataSource: ResponseObject<Category[]>
	selectedEntries: GridInputSelectionModel
	onSelectionChanged: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => void
}

export default function UserTable({dataSource, selectedEntries, onSelectionChanged}: UserTableProps) {

	const gridColumns: GridColDef[] = [
		makeIdColumn(), {
			field: 'nickName',
			headerName: 'Tên',
			width: 200,
			renderCell: null
		}, {
			field: 'email',
			headerName: 'Email',
			flex: 1,
			renderCell: (data) => (
				<>{data.row.email || "N/A"}</>
			)
		}, {
			field: 'phone',
			headerName: 'Số điện thoại',
			width: 200,
			renderCell: (data) => (
				<>{data.row.phone || "N/A"}</>
			)
		}, {
			field: 'kyc',
			headerName: 'Xác minh ',
			width: 200,
			renderCell: null
		}, {
			field: 'roles',
			headerName: 'Phân quyền',
			width: 300,
			renderCell: (data) => {
				return <>
					{data.row.roles.map((role, index) => (
						<React.Fragment key={index}><Chip label={role?.name} size="small" color="success" variant='outlined'/>&nbsp;
						</React.Fragment>
					))}
				</>
			},
		}, {
			field: 'currentRole',
			headerName: 'Thêm quyền',
			width: 300,
			renderCell: (data) => <UserRoleModal data={data}/>,
		},
	];

	const gridOptions = dataGridOptions({
		columns: gridColumns,
		rows: dataSource.data || [],
		selectionModel: selectedEntries,
		onSelectionModelChange: onSelectionChanged
	})

	return (
		<DataGrid {...gridOptions} />
	)
}
