export type Language = {
	localeCode: string
	name: string
}

export const languages: Language[] = [{
	localeCode: 'vi_VN',
	name: 'Tiếng Việt'
}, {
	localeCode: 'en_US',
	name: 'Tiếng Anh'
}];
