// ** React Imports
import { ReactNode } from 'react'

// ** MUI Imports
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// ** Layout Imports
// !Do not remove this Layout import
import Layout from '@/@materialize/layouts/Layout'

// ** Navigation Imports
import navItems from '@/config/navigation'
import AppBarContent from './components/AppBarContent'

// ** Hook Import
import { useSettings } from '@/@materialize/hooks/useSettings'
import logo from '@/assets/logo.svg'

interface Props {
  children: ReactNode
  contentHeightFixed?: boolean
}

const UserLayout = ({ children }: Props) => {
  // ** Hooks
  const { settings, saveSettings } = useSettings()
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  if (hidden && settings.layout === 'horizontal') {
    settings.layout = 'vertical'
  }

  return (
    <Layout
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      // contentHeightFixed={false}
      verticalLayoutProps={{
        navMenu: {
          branding: () => <img src={logo} alt="Divega" style={{ width: 160 }} />,
          navItems: navItems()
        },
        appBar: {
          content: props => (
            <AppBarContent
              hidden={hidden}
              settings={settings}
              saveSettings={saveSettings}
              toggleNavVisibility={props.toggleNavVisibility}
            />
          )
        }
      }}>
      {children}
    </Layout>
  )
}

export default UserLayout
