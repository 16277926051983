import PilledTabList from '@/@core/components/PilledTabList';
import { languages } from '@/config/languages';
import { Tab } from '@mui/material';
import { ReactElement } from 'react';
import {
	TabList,
	TabListProps
} from '@mui/lab';
import * as React from 'react';
import { styled } from '@mui/material/styles';

export type LanguageTabBarProps = {
	variant?: 'pilled' | 'card'
	onChange?: (event: React.SyntheticEvent, value: any) => void;
}

const CardTabList = styled(TabList)<TabListProps>(({ theme }) => ({
	'& .MuiTabs-flexContainer': {
		position: 'relative'
	},
	'& .MuiTabs-flexContainer:before': {
		content: '""',
		display: 'block',
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		height: 1,
		background: theme.palette.primary.main
	},
	'& .MuiTabs-indicator': {
		display: 'none'
	},
	'& .Mui-selected': {
		backgroundColor: theme.palette.common.white,
		color: `${theme.palette.primary.main} !important`,
		borderColor: `${theme.palette.primary.main} !important`,
	},
	'& .MuiTab-root': {
		minHeight: 38,
		minWidth: 110,
		borderRadius: 6,
		border: `1px solid transparent`,
		borderBottom: 0,
		borderBottomLeftRadius: `0 !important`,
		borderBottomRightRadius: `0 !important`,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	}
}));

export default function LanguageTabBar ({ variant, onChange }: LanguageTabBarProps) {
	const items = languages.map(language => (
		<Tab key={language.localeCode} value={language.localeCode} label={language.name} />
	))

	const variants = {
		pilled: (tabItems: ReactElement[]) => <PilledTabList onChange={onChange}>{tabItems}</PilledTabList>,
		card: (tabItems: ReactElement[]) => <CardTabList onChange={onChange}>{tabItems}</CardTabList>
	}

	return variants[variant]
		? variants[variant](items)
		: <TabList onChange={onChange}>{items}</TabList>
}