import {
	Outlet
} from 'react-router-dom';
import { SettingsConsumer, SettingsProvider } from '@/@materialize/context/settingsContext'
import ThemeComponent from '@/@materialize/theme/ThemeComponent'
import ReactHotToast from '@/@materialize/styles/libs/react-hot-toast'
import UserLayout from '@/layouts/UserLayout'
import { Toaster } from 'react-hot-toast';
import AuthProvider, {
	AuthStatus,
	useAuth
} from '@/context/AuthContext';
import { ReactNode } from 'react';
import { CircularProgress } from '@mui/material';

const AuthGuard = ({ children }: { children: ReactNode }) => {
	const { user, status, login } = useAuth();

	if (status === AuthStatus.INITIAL) {
		return <CircularProgress />
	}

	if (user === null) {
		login();
		return <CircularProgress />
	}

	return (<>{children}</>);
}

export default function App() {
	return (
		<AuthProvider>
			<SettingsProvider>
				<SettingsConsumer>
					{({ settings }) => {
						return (
							<AuthGuard>
								<ThemeComponent settings={settings}>
									<UserLayout contentHeightFixed={false}>
										<Outlet />
									</UserLayout>
									<ReactHotToast>
										<Toaster position={settings.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
									</ReactHotToast>
								</ThemeComponent>
							</AuthGuard>
						)
					}}
				</SettingsConsumer>
			</SettingsProvider>
		</AuthProvider>
	)
}
