import Users from "@/modules/User/pages";
import {loader as usersLoader} from "@/modules/User/pages";

export default function userRoutes() {
	return [{
		path: 'users',
		title: 'Danh sách tài khoản',
		loader: usersLoader,
		element: <Users />,
	}];
}
