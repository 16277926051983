import { client } from '@/modules/Category/services/CategoryService';
import {
	Await,
	defer,
	useLoaderData,
	Outlet,
	Link
} from 'react-router-dom';
import React, {
	ReactElement,
	Suspense,
	useState
} from 'react';
import { Category } from '@/modules/Category/entities/Category';
import {
	Button,
	Card,
	CircularProgress
} from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import CategoryTableHeader from '@/modules/Category/components/CategoryTableHeader';
import CategoryTable from '@/modules/Category/components/CategoryTable';
import PageHeader from '@/layouts/components/PageHeader';
import icons from '@/config/icons';
import { ResponseObject } from '@/@core/entities/Http';
import { Icon } from '@iconify/react';

export type CategoryLoaderResult = {
	categories: Promise<ResponseObject<Category[]>>
}

export const loader = ({ request }) => {
	const url = new URL(request.url);
	return defer({
		categories: client.fetchItems(Object.fromEntries(url.searchParams))
	});
}

export default function CategoryList() {
	const data = useLoaderData() as CategoryLoaderResult;
	const [selectedCategories, setSelectedCategories] = useState<GridSelectionModel>([]);

	const actions: ReactElement[] = [
		<Button
			component={Link}
			startIcon={<Icon icon={icons.ADD} />}
			to="add"
			relative="path"
			key="button-add-category"
			variant="contained">
			Thêm danh mục
		</Button>
	]

	return (
		<>
			<PageHeader actions={actions} />
			<Card>
				<CategoryTableHeader selectedEntries={selectedCategories} />
				<Suspense fallback={<CircularProgress />}>
					<Await
						resolve={data.categories}
						children={(response) => (
								<CategoryTable
									dataSource={response}
									selectedEntries={selectedCategories}
									onSelectionChanged={setSelectedCategories}
								/>
							)
						}
					/>
				</Suspense>
			</Card>
			<Outlet />
		</>
	)
}
