import React, {
	useCallback,
	useMemo,
	useState
} from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText
} from '@mui/material';

export type DialogOptions = {
	title?: string
	message?: string
	onClose?: () => void
	onConfirmed?: () => void
	cancelText?: string
	confirmText?: string
}

export default function useConfirmDialog () {
	const [opening, setOpening] = useState(false);
	const [options, setOptions] = useState<DialogOptions>({
		title: 'Xác nhận',
		cancelText: 'Hủy',
		confirmText: 'Xác nhận',
	});

	const renderDialog = useCallback(() => {
		const handleClose = () => {
			setOpening(false);
			options.onClose && options.onClose();
		}

		const handleConfirm = () => {
			setOpening(false);
			options.onConfirmed && options.onConfirmed();
		}

		return opening && (
			<Dialog open={opening}>
				<DialogContent>
					<DialogContentText>{options.message}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{options.cancelText}</Button>
					<Button onClick={handleConfirm}>{options.confirmText}</Button>
				</DialogActions>
			</Dialog>
		);
	}, [opening, options]);

	return useMemo(() => ({
		showConfirm: (options: DialogOptions) => {
			setOptions((prevOptions) => ({
				...prevOptions,
				...options
			}));
			setOpening(true);
		},
		renderDialog
	}), [
		opening,
		renderDialog
	])
}
