import CategoryList, { loader as categoriesLoader } from '@/modules/Category/pages';
import CategoryForm, { loader as categoryLoader } from '@/modules/Category/components/CategoryForm';
import AsyncFormDialog from '@/@core/components/AsyncFormDialog';

export default function categoryRoutes() {
	return [{
		path: 'categories',
		title: 'Danh mục sản phẩm',
		loader: categoriesLoader,
		element: <CategoryList />,
		children: [{
			path: ':categoryId',
			loader: categoryLoader,
			element: (
				<AsyncFormDialog
					title={(response) => response.data.id ? 'Chỉnh sửa danh mục' : 'Thêm mới danh mục'}
					children={(response) => (
						<CategoryForm formData={response.data} />
					)}
				/>
			)
		}]
	}];
}
