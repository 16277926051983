import { isEmpty } from 'lodash';
import { omit } from 'lodash'
import createClient, {
	FilterBuilderParams,
	prepareLocalizationData
} from '@/@core/services/ApiService';
import User from "@/modules/User/entities/User";

/**
 * Making query strings to filter data from APIs with the aid of a helper function
 *
 * @param url
 * @param params
 */
const filterBuilder = ({ url, params }: FilterBuilderParams) => {
	if (!isEmpty(params.keyword)) {
		url.searchParams.append('filter', `contents.name:[LIKE,${params.keyword}]`)
	}

	if (!isEmpty(params.status) && params.status !== 'ALL') {
		url.searchParams.append('filter', `status:[EQUALS,${params.status}]`)
	}

	return url;
}

const hooks = {
	/**
	 * Getting data ready to submit to the backend for saving
	 * @param data
	 */
	// prepareDataForSave: (data: Role) => {
	// 	return prepareLocalizationData<Role>(
	// 		omit(data, ['createdBy', 'createdAt'])
	// 	);
	// }
}

export const client = createClient<User>('users', filterBuilder, hooks);
export const clientRole = createClient<User>('roles', filterBuilder, hooks);
