// ** React Imports
import { useState, SyntheticEvent, Fragment } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

// ** Icon Imports
import Icon from '@/@materialize/components/icon'

// ** Type Imports
import { Settings } from '@/@materialize/context/settingsContext'
import { useAuth } from '@/context/AuthContext';

interface Props {
  settings: Settings
}

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const UserDropdown = (props: Props) => {
  // ** Props
  const { settings } = props

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  // ** Vars
  const { direction } = settings
	const { user, logout } = useAuth();

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url?: string) => {
    setAnchorEl(null)
  }

  // const styles = {
  //   py: 2,
  //   px: 4,
  //   width: '100%',
  //   display: 'flex',
  //   alignItems: 'center',
  //   color: 'text.primary',
  //   textDecoration: 'none',
  //   '& svg': {
  //     mr: 2,
  //     fontSize: '1.375rem',
  //     color: 'text.primary'
  //   }
  // }

  const handleLogout = () => {
		logout();
    handleDropdownClose()
  }

  return (
    <Fragment>
      <Badge
        overlap='circular'
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: 'pointer' }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Avatar
          alt={user?.email}
          onClick={handleDropdownOpen}
          sx={{ width: 40, height: 40 }}
          src={user?.avatar}
        />
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        {/*<Box sx={{ pt: 2, pb: 3, px: 4 }}>*/}
        {/*  <Box sx={{ display: 'flex', alignItems: 'center' }}>*/}
        {/*    <Badge*/}
        {/*      overlap='circular'*/}
        {/*      badgeContent={<BadgeContentSpan />}*/}
        {/*      anchorOrigin={{*/}
        {/*        vertical: 'bottom',*/}
        {/*        horizontal: 'right'*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <Avatar alt='John Doe' src='/images/avatars/1.png' sx={{ width: '2.5rem', height: '2.5rem' }} />*/}
        {/*    </Badge>*/}
        {/*    <Box sx={{ display: 'flex', ml: 3, alignItems: 'flex-start', flexDirection: 'column' }}>*/}
        {/*      <Typography sx={{ fontWeight: 600 }}>John Doe</Typography>*/}
        {/*      <Typography variant='body2' sx={{ fontSize: '0.8rem', color: 'text.disabled' }}>*/}
        {/*        Admin*/}
        {/*      </Typography>*/}
        {/*    </Box>*/}
        {/*  </Box>*/}
        {/*</Box>*/}
        {/*<Divider sx={{ mt: '0 !important' }} />*/}
        {/*<MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>*/}
        {/*  <Box sx={styles}>*/}
        {/*    <Icon icon='mdi:account-outline' />*/}
        {/*    Profile*/}
        {/*  </Box>*/}
        {/*</MenuItem>*/}
        {/*<MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>*/}
        {/*  <Box sx={styles}>*/}
        {/*    <Icon icon='mdi:email-outline' />*/}
        {/*    Inbox*/}
        {/*  </Box>*/}
        {/*</MenuItem>*/}
        {/*<MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>*/}
        {/*  <Box sx={styles}>*/}
        {/*    <Icon icon='mdi:message-outline' />*/}
        {/*    Chat*/}
        {/*  </Box>*/}
        {/*</MenuItem>*/}
        {/*<Divider />*/}
        {/*<MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>*/}
        {/*  <Box sx={styles}>*/}
        {/*    <Icon icon='mdi:cog-outline' />*/}
        {/*    Settings*/}
        {/*  </Box>*/}
        {/*</MenuItem>*/}
        {/*<MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>*/}
        {/*  <Box sx={styles}>*/}
        {/*    <Icon icon='mdi:currency-usd' />*/}
        {/*    Pricing*/}
        {/*  </Box>*/}
        {/*</MenuItem>*/}
        {/*<MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>*/}
        {/*  <Box sx={styles}>*/}
        {/*    <Icon icon='mdi:help-circle-outline' />*/}
        {/*    FAQ*/}
        {/*  </Box>*/}
        {/*</MenuItem>*/}
        {/*<Divider />*/}
        <MenuItem
          onClick={handleLogout}
          sx={{ py: 2, '& svg': { mr: 2, fontSize: '1.375rem', color: 'text.primary' } }}
        >
          <Icon icon='mdi:logout-variant' />
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default UserDropdown
