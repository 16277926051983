import dashboardRoutes from "@/modules/Dashboard/routes";
import categoryRoutes from "@/modules/Category/routes";
import customersRoutes from "@/modules/Customers/routes";
import providerRoutes from "@/modules/Provider/routes";
import orderRoutes from '@/modules/Order/routes';
import userRoutes from "@/modules/User/routes";
import permissionRoutes from "@/modules/Role/routes";

export default function getRoutesConfig() {
	return [
		...dashboardRoutes(),
		...categoryRoutes(),
		...providerRoutes(),
		...orderRoutes(),
		...customersRoutes(),
		...userRoutes(),
		...permissionRoutes()
	];
}
